<template>
  <div class="box">
    <div class="content">
      <iframe
        src="./2d/drawingsolr.html"
        ref="iframe"
        width="100%"
        height="100%"
        scrolling="no"
        frameborder="0"
        id="iframe"
      ></iframe>
    </div>
    <div class="btnbox">
      <!-- <span @click="removeEvent">删除</span> -->
      <span @click="resetEvent">重置</span>
      <span @click="confirmEvent">确认</span>
    </div>
    <p class="attention">注: 请轻触两下需要删除的组件即可删除</p>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { onMounted, toRefs, reactive, nextTick, ref } from 'vue'

export default {
  setup() {
    const data = reactive({
      iframeWin: null,
      customer: window.customer
    })
    const iframe = ref(null)
    const router = useRouter()

    const methodsMap = {
      sendMsgTo2D(v) {
        data.iframeWin.postMessage(v, '*')
      },
      // removeEvent() {},
      resetEvent() {
        methodsMap.sendMsgTo2D('重置排布图')
      },
      confirmEvent() {
        methodsMap.sendMsgTo2D('更新排布图')// 在排布图页面点击确认按钮,给iframe发送消息通知更新排布图
        router.push({
          path: '/drawing',
          // query: {
          //   state: 1
          // }
        })
      }
    }

    onMounted(() => {
      nextTick(() => {
        // if (data.iframeWin === null) {
        //   data.iframeWin = sessionStorage.getItem('iframeData')
        //   if (sessionStorage.getItem('iframeData') === null) {
        //     data.iframeWin = iframe.value.contentWindow
        //     sessionStorage.setItem('iframeData', data.iframeWin)
        //   }
        // }
        data.iframeWin = iframe.value.contentWindow
        // sessionStorage.setItem('iframeData', data.iframeWin)
        // console.log(iframe.value.contentWindow, 'iframe数据********')
      })
    })

    return { ...toRefs(data), ...methodsMap, iframe }
  }
}
</script>
<style scoped lang="scss">
.box {
  .content {
    width: 100%;
    height: 500px;
    padding-top: 5px;
  }
  .btnbox {
    text-align: center;
    span {
      display: inline-block;
      width: 109px;
      height: 36px;
      line-height: 36px;
      background: #04cc66;
      border-radius: 8px;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      margin-right: 8px;
      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
  }
  .attention{
    color: red;
    text-align: center;
    margin-top: 10px;
  }
}
</style>
